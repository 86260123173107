<template>
    <div
        id="app"
        :class="{ 'l-container': $env === 'development' }"
        class="js-fsales-lagerwagen"
    >
        <el-row :gutter="20" type="flex" style="flex-wrap: wrap;">
            <el-col
                :xs="24"
                :sm="12"
                :md="8"
                v-for="(car, key) in cars"
                :key="key"
            >
                <car-thumb :carId="car['Fahrzeug-ID']"></car-thumb>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import CarThumb from "@/components/CarThumb.vue";
import axios from "axios";
import { setup } from "@/mixins/setup";

export default {
    mixins: [setup],
    components: {
        CarThumb,
    },
    data() {
        return {
            showAllCars: false,
        };
    },
    mounted() {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        document.addEventListener("car", (event) => {
            this.getCars(event, source);
        });
        this.$store.dispatch("cars/getCars", {
            searchField: "",
            value: "",
            cancelTokenSource: source,
        });
    },
    computed: {
        cars() {
            let result = [];
            let counter = 0;
            for (let carId in this.$store.state.cars.all) {
                result.push(this.$store.state.cars.all[carId]);
                counter++;
                if (!this.showAllCars && counter === 3) {
                    break;
                }
            }
            return result;
        },
    },
    methods: {
        getCars(event, cancelTokenSource) {
            cancelTokenSource.cancel("Operation canceled by the client.");
            const models = event.detail.models;
            this.$store
                .dispatch("cars/getCars", {
                    searchField: "Name Modell Type",
                    value: models,
                })
                .then((response) => {
                    if (response.data.Result) {
                        this.showAllCars = true;
                    }
                });
        },
    },
};
</script>

<style></style>
