import Vue from "vue";
import FilteredCars from "./FilteredCars.vue";
import store from "../../store";
import setup from "../../utils/setup";

setup(Vue);

new Vue({
    store,
    render: h => h(FilteredCars)
}).$mount("#app");
